@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    position: relative;
}


::webkit-scrollbar {
    width: 4px;
}

::webkit-scrollbar-track {
    background: #f1f1f1;
}

::webkit-scrollbar-thumb {
    background: #888;
}

::webkit-scrollbar-thumb:hover {
    background: #555;
}

html {
    font-size: 62.5%;
    -webkit-text-size-adjust: none;
    font-family: Raleway;
}

a {
    color: inherit;
    text-decoration: none;
    outline: none !important;
}

input[type="color"] {
    cursor: pointer;
}


.game-cards-container {
    flex-direction: column;
    flex-wrap: wrap;
}

#portrait-denier {
    position: absolute;
    z-index: 10;
    color: white;
    background-color: black;
    /* transform: rotate(90deg); */
    /* transform-origin: left top; */
    left: -5%;
    top: -5%;
    width: 110vw;
    height: 110vh;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    display: none;
    font-family: "Raleway";
}

#portrait-denier img {
    /*width: 80vw;*/
    width: 70vh;
}

.black-background {
    width: 100%;
    height: 10000%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: black;
}

.App-title {
    font-size: 2.5rem;
    font-weight: 900;
}

#gallery-filter-name-title {
    padding-right: 1rem;
    font-size: 2rem;
    border-radius: 1rem;
    border-width: 1px;
    border-style: solid;
    height: 4rem;
    width: 30rem;
    text-align: end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

#gallery-filter-name-container {
    min-width: 6rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.questionIcon {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 50%;
    left: -25%;
    transform: translate(0px, -50%);
}

.questionIcon img {
    width: 100%;
}

.questionIcon .question-answer {
    width: 16rem;
    height: auto;
    position: absolute;
    border: 1px solid #838383;
    border-radius: 1rem;
    background-color: #d3d3d3;
    color: #838383;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 1rem;
    margin-top: 1rem;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease-in-out all;
}

.questionIcon:hover .question-answer {
    pointer-events: auto;
    opacity: 1;
}

#gallery-filter-name-container:hover #gallery-filter-name-content {
    display: block;
}

#gallery-filter-name-content {
    position: absolute;
    background-color: #2d4f6d;
    min-width: 20rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 0%;
    right: 105%;
    font-weight: 400;
    font-size: 2rem;
    max-height: calc(100vh - 8rem);
    /* overflow-y: auto; */
    cursor: default;
    border-radius: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-width: 1px;
    border-style: solid;
}

#gallery-filter-name-content div {
    color: black;
    padding: 0.5rem 1rem;
    display: block;
    display: flex;
    flex: row;
    align-items: center;
    justify-content: flex-end;
}

#gallery-filter-name-content div:hover {
    background-color: #2e76bd;
    color: white;
}

#gallery-filter-value-title {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    font-size: 2rem;
    border-radius: 1rem;
    border-width: 1px;
    border-style: solid;
    height: 4rem;
    text-align: end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

#gallery-filter-value-container {
    min-width: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#gallery-filter-value-content {
    position: absolute;
    background-color: #2d4f6d;
    min-width: 20rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 3;
    top: 140%;
    right: 0%;
    font-weight: 400;
    font-size: 2rem;
    max-height: calc(100vh - 8rem);
    /* overflow-y: auto; */
    cursor: default;
    border-radius: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    max-height: 50vh;
}

#gallery-filter-value-content div,
#gallery-filter-value-content b {
    color: black;
    padding: 1rem 1rem;
    display: block;
    display: flex;
    flex: row;
    align-items: center;
    justify-content: flex-end;
    text-align: end;
}

.dropdown:hover #gallery-filter-value-content {
    display: block;
}

#gallery-filter-value-content div:hover {
    background-color: #689db3;
    color: white;
}

.Content {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: white;
    background-color: white;
    height: 100%;
    padding-top: 8rem;
    overflow-x: hidden;
}

.Main {
    display: flex;
    max-height: 100vh;
    overflow: hidden;
    height: 100%;

    & > div {
        display: flex;
        flex: 1;
        overflow-y: auto;
        width: 100%;
        height: 100%;
    }
}

#game {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    z-index: 10;
    border-width: 0;
}

.Content-central {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    /* width: 65rem; */
    /* padding: 1rem; */
    /* margin-top: 3rem; */
}

#choose-avatar-subtitle {
    font-size: 2rem;
}

.Content-central-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.register-inputs-collumn-portrait {
    display: none;
}

.teacher-box-text-portrait {
    display: none;
}

.teacher-box-text-landscape {
    display: block;
}

#login-logo {
    width: 25rem;
    /* margin-bottom: 1rem; */
}

.oauth-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: 13rem; */
    /*margin: 3rem;*/
    margin: 2rem;
    height: 7rem;
    width: 7rem;
}

.oauth-icons > img {
    /*height: 5rem;*/
    height: 100%;
    transition: all 0.2s ease-in-out;
}

.oauth-icons > img:hover {
    cursor: pointer;
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
}

.oauth-icons > button > img {
    height: 5rem;
    transition: all 0.2s ease-in-out;
}

.oauth-icons > button > img:hover {
    cursor: pointer;
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
}

.banner-popup {
    width: 100%;
    height: 100%;
    /*ABSOLUTE FOR INTERNAL POPUP AND FIXED TO PAGE POPUP*/
    position: absolute;
    /*position: fixed;*/
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*ACTIVE Z-INDEX ONLY FOR PAGE BANNER AND NOT INTERNAL ONE*/
    /*z-index: 999;*/
    /*background-color: rgba(0, 0, 0, 0.75);*/
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out all;
}

.banner-popup.active {
    opacity: 1;
    /*PUT AUTO ONLY IF YOU WANT TO BLOCK SCROLL WHEN ACTIVE*/
    pointer-events: none;
}

.banner-popup .popup-box {
    width: 77rem;
    height: 55rem;
    position: absolute;
    top: 42%;
    right: 4%;
    transform: translate(0%, -50%);
    pointer-events: none;
}

.banner-popup .popup-box.active {
    pointer-events: auto;
}

.popup-box .banner-popup-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top right;
}

.popup-box .banner-popup-close {
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: 3%;
    right: 2%;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
    opacity: 0.8;
    transition: 0.2s ease-in-out all;
}

.popup-box .banner-popup-close:hover {
    opacity: 1;
}

#popup-play-button {
    position: absolute;
    height: 5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    font-size: 2.1rem;
    bottom: 6%;
    right: 3.5%;
}

.regulation-text {
    width: 90%;
    float: right;
    margin-top: 2rem;
    font-size: 2rem;
    text-align: right;
}

.home-banner {
    width: 90%;
}

#home-full-banner-btn {
    position: absolute;
    top: 78%;
    right: 8%;
    height: 6rem;
    width: 18rem;
    border-radius: 3rem;
    font-size: 2.5rem;
}

#home-half-banner-btn {
    position: absolute;
    top: 83%;
    right: 8%;
}

.our-partners-text {
    color: #1fb2de;
    width: 90%;
    text-align: start;
    font-size: 3rem;
    font-weight: 800;
    display: block;
}

#Choose-avatar {
    width: 70rem;
}

.Content-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    margin-top: 3rem;
}

.chooseYourCityText-portrait {
    display: none;
}

.chooseYourCityText {
    display: block;
}

#separator {
    height: 1px;
    width: 5rem;
    background-color: #a3a0a0;
    top: -1rem;
}

#Selected-game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
}

#Selected-game-container > img {
    height: 25vh;
}

#Selected-game-container > video {
    height: 25vh;
}

.Content-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10rem;
    opacity: 0;
    cursor: default;
    pointer-events: none;
}

.Content-right > img {
    height: 16rem;
    margin-top: 2rem;
    cursor: pointer;
}

.Content-right-title {
    font-size: 3rem;
    font-weight: 400;
    margin-top: 2rem;
}

.ChooseCity-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#ChooseCity {
    width: 90rem;
    padding: 3rem;
    font-weight: 800;
}

#ChooseCity--title {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    position: absolute;
    left: 11%;
    top: 76%;
    color: #388bde;
    z-index: 2;
}

#ChooseCity--title-portrait {
    margin-left: 10px;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    color: #388bde;
    text-align: left;
    display: none;
}

#brasilmap {
    width: 100%;
    display: flex;
    justify-content: center;
    height: fit-content;
    position: relative;
}

#brasilmapimg {
    width: 80%;
    position: relative;
    /* position: absolute; */
}

.buttonButtons-list-map {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 4%;
    right: -40%;
}

.buttonButtons-list-map-portrait {
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.text-input {
    color: #202024;
    height: 5rem;
}

.text-input input {
    width: 100%;
    height: 100%;
    padding-left: 2rem;
    font-size: 2rem;
    border-radius: 2.5rem;
    border: 1px solid #4b8ac0;
}

.text-input .eye-icon {
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 3%;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
}

.eye-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.register-additional-terms-text {
    width: 160%;
}

.login-additional-terms-text {
    width: 100%;
}

.error-note {
    width: 80%;
    font-size: 1.8rem;
    margin-bottom: 0rem;
    color: rgba(255, 0, 0, 0);
    height: 0px;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
}

.error-note.active {
    margin-bottom: 1rem;
    color: rgba(255, 0, 0, 1);
    height: fit-content;
    transition: all 0.4s ease-in-out all;
}

.inline-link {
    font-weight: bold;
    color: #1366d8;
}

.inline-link:hover {
    cursor: pointer;
}

#login-register-button {
    width: 80%;
    height: 5rem;
    border-radius: 2.5rem;
    margin: 2rem;
}

#choose-avatar-title {
    /* margin-bottom: 2rem; */
    font-size: 2.5rem;
    font-weight: 800;
    color: #388bde;
}

#choose-avatar-prompt {
    display: flex;
    justify-content: flex-start;
    width: 80%;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

#Avatar-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

#Avatars-icon-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}


#button-salvar-profile {
    width: 80%;
    height: 5rem;
    border-radius: 2.5rem;
}

#button-reset-password {
    width: 80%;
    height: 5rem;
    border-radius: 2.5rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
}

#VideoGallery {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 100%;
}

#video-gallery-play-btn-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 7rem;
    padding-bottom: 7rem;
}

#video-gallery-play-btn-container > input {
    height: 6rem;
    border-radius: 3rem;
    font-size: 3rem;
}

#Journal {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    min-height: fit-content;
}

#Journal-central {
    padding: 3rem;
    width: max-content;
    width: 100%;
}

#Journal-playbutton-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    margin-top: 2rem;
}

#Journal-sidebar {
    display: flex;
    flex-direction: column;
    background-color: #e8e8e8;
    width: fit-content;
    height: 100%;
}

#Journal-sidebar > img {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    width: 20rem;
    cursor: pointer;
}

#Journal-sidebar > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    font-size: 2rem;
}

#Journal-subheader-title-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-weight: 800;
}

#Journal-subheader-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: black;
    top: -3rem;
    left: -5rem;
}

#Journal-profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
}

#Journal-profile-name {
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 5rem;
    padding: 1rem;
    padding-left: 7rem;
    padding-right: 2rem;
    font-size: 2.5rem;
    font-weight: 400;
    background-color: black;
    color: white;
    left: -6rem;
    top: -3rem;
}

#Journal-avatar {
    height: 15rem;
    z-index: 1;
}

#Journal-subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 5rem;
}

#Journal-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

#Journal-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#Journal-game > img {
    width: 35rem;
    margin-bottom: 2rem;
}

#Journal-game > video {
    width: 35rem;
    margin-bottom: 2rem;
}

#Journal-game > div {
    margin-top: 0.5rem;
}

.box-journal-top {
}

.box-journal-top > img {
    width: 35rem;
    margin-bottom: 2rem;
    object-fit: contain;
}

.box-journal-top > video {
    width: 35rem;
    margin-bottom: 2rem;
    object-fit: contain;
}

.box-journal-items {
    width: 35rem;
}

.box-journal-items > img {
    margin-bottom: 2rem;
}

.additional-text-journal {
    width: 35rem;
    font-size: 2.8rem;
    line-height: 3.6rem;
    text-align: left;
    margin-top: 4rem !important;
}

.Journal-world {
    background-color: black;
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 32%;
    padding-right: 2rem;
    padding-left: 2rem;
    color: black;
    overflow-y: auto;
    /*border-radius: 4rem;*/
    overflow: visible;
}

.box-mission-title-track {
}

.box-mission-title-track > img {
    width: 40%;
}

.Journal-world-logo-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    height: 14rem;
    width: 70%;
}

#Journal-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    padding-left: 2rem;
}

.Journal-track {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /*align-items: flex-start;*/
    color: white;
    font-weight: 700;
}

.mission-title {
    margin: 2rem;
}

.Journal-track > div {
    width: 100%;
}

.challenge-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.challenge-list-title {
    display: flex;
    justify-content: flex-start;
}

.challenge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3rem;
    border-radius: 0.8rem;
    border: 1px dashed #838383;
    width: fit-content;
    font-size: 1.5rem;
    transition: all 0.2s ease-in-out;
    margin-top: 1rem;
}

.challenge.active {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2);
    z-index: 1;
}

.challenge.inactive {
    cursor: default;
}

.challenge > img {
    height: 3rem;
    margin-right: 0.5rem;
}

.mission {
    cursor: pointer;
    height: 4rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.mission.inactive {
    cursor: default;
    pointer-events: none;
}

.mission > img {
    height: 2rem;
}

#play-world-button {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    font-size: 3rem;
    height: 6rem;
    border-radius: 3rem;
}

.progress-bar {
    height: 3rem;
    background-color: #4b8ac0;
    border-radius: 1.5rem;
}

#welcome-text {
    font-size: 2.8rem;
    text-align: left;
    font-weight: 500;
    margin-top: 3rem;
    margin-bottom: 4rem;
}

.choose-world-filter-galery {
    display: none;
}

#VideoGallery-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.VideoGallery-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 32%;
}

.public-video-gallery-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

#journal-to-videogallery {
    border-radius: 3rem;
    border-color: #7f7f7f;
    border-style: dotted;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 17rem;
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-right: 2rem;
    cursor: pointer;
}

#journal-to-videogallery img {
    width: 6rem;
}

#gallery-filters-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 2rem 2rem 2rem;
    font-weight: 600;
    font-size: 2rem;
    z-index: 2;
}

.gallery-filters option {
    padding-left: 4rem;
}

.video-thumbnail {
    height: 25rem;
}

.filters-used-container {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.filters-used-container .filter-used-text {
    font-size: 2rem;
    margin-bottom: 1rem;
    display: none;
}

.filters-used-container .filter-used-text.active {
    display: block;
}

.filters-used-container:last-child {
    margin-bottom: 0rem;
}

.upload-video-bg {
    height: 55%;
    aspect-ratio: 1.8181;
    background-color: #e1e1e1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-weight: 600;
    padding: 2rem;
    font-size: 1.8rem;
}

.evaluations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
}

.evaluations * {
    margin-right: 1rem;
}

.evaluations img {
    width: 2.5rem;
}

.evaluations > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.evaluations > div > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.video-container {
    width: 100%;
    margin-right: 2rem;
    margin-bottom: 3rem;
}

.video-container-caption {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 1.8rem;
}

.video-container-caption div {
    display: inline-block;
    text-align: left;
    justify-content: flex-start;
    font-size: 1.8rem;
    line-height: 2.6rem;
    text-align: left;
    color: black;
}

.video-playbutton {
    position: absolute;
    top: 25%;
    left: 35%;
    width: 27%;
    cursor: pointer;
}

.video-upload {
    position: absolute;
    top: 20%;
    left: 35%;
    width: 33%;
    cursor: pointer;
}

.video-trash {
    position: absolute;
    top: 3%;
    left: 87%;
    width: 11%;
    cursor: pointer;
}

.video-player-error {
    width: 100%;
    aspect-ratio: 1.8181;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #383838;
    color: white;
    padding: 4rem;
    font-weight: 700;
}

#confirm-video-delete {
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    font-family: "Raleway";
    top: 0%;
    left: 0%;
    transition: all 0.3s ease-in-out;
}

#confirm-video-delete-background {
    position: fixed;
    background-color: black;
    width: 100vw;
    height: 100vh;
    top: 0%;
    left: 0%;
    opacity: 0.85;
}

#confirm-video-delete.inactive {
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
}

#confirm-video-delete-content {
    line-height: 4rem;
    font-weight: 600;
    font-size: 2.5rem;
    padding: 3rem;
    width: 40rem;
    background-color: #eeeeee;
    border-radius: 3rem;
}

#confirm-video-delete img {
    position: absolute;
    width: 28%;
    top: -30%;
    left: 36%;
}

#popup-video {
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    font-family: "Raleway";
    top: 0%;
    left: 0%;
    transition: all 0.3s ease-in-out;
}

#popup-video-background {
    position: fixed;
    background-color: black;
    width: 100vw;
    height: 100vh;
    top: 0%;
    left: 0%;
    opacity: 0.85;
}

.video-size {
    width: 64vw;
    aspect-ratio: 1.6;
}

.rsme-embed div {
    width: 100%;
    height: 100%;
}

.rsme-spinner {
    width: 5rem !important;
    height: 5rem !important;
}

.rsme-spinner + div {
    height: unset !important;
    font-size: 2rem !important;
    line-height: 3rem !important;
}

#popup-video.inactive {
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
}

#popup-video video {
    height: 100%;
    width: 100%;
}

#popup-video img {
    width: 5%;
    position: absolute;
    left: 94%;
    top: 2%;
    cursor: pointer;
}

#notices {
    padding: 4rem;
    position: absolute;
    top: 10%;
    right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 20%;
    height: 75%;
    color: black;
    font-weight: 700;
}

#notices div {
    padding: 2rem;
    border-radius: 1.5rem;
}

.dashboard {
    width: 70%;
    /* height: 100%; */
    padding: 3rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.dashboard-scroll {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    width: 65rem;
    padding: 1rem;
    width: 40%;
}

.dashboard-separator {
    width: 1px;
    height: 100%;
    background-color: lightgrey;
    margin-left: 3rem;
    margin-right: 3rem;
}

.dashboard-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.dashboard-block.landscape {
    display: flex;
}

.dashboard-block.portrait {
    display: none;
}

.dashboard-subtitle {
    font-weight: 900;
    color: #388bde;
}

#terms-and-conditions {
    padding: 6rem;
    text-align: left;
    font-size: 2.5rem;
    line-height: 4rem;
}

/*@media screen and (orientation: landscape) {
      html {*/
/* Rotate the content container */
/*transform: rotate(-90deg);
        transform-origin: left top;*/
/* Set content width to viewport height */
/*width: 100vh;*/
/* Set content height to viewport width */
/*height: 100vw;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
      }
    }*/

/* @media screen and (orientation: landscape) {
  html {
    transform: rotate(90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

/* RESPONSIVE LAYOUT */
@media screen and (orientation: landscape) {
    #portrait-denier {
        display: flex;
        /*display: none;*/
    }
}

@media only screen and (min-width: 2400px) and (orientation: landscape) {
    /* OK */
    /* html {
        font-size: 12px;
    }
} 

@media only screen and (max-width: 1850px) {
    /* html {
        font-size: 9px;
    } */
}

@media screen and (max-width: 1400px) {
    /* html {
        font-size: 7px;
    } */
}

@media screen and (max-width: 1100px) and (orientation: landscape) {
    /* html {
        font-size: 6px;
    } */
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
    /* html {
        font-size: 6px;
    } */
}

@media screen and (max-width: 850px) and (orientation: landscape) {
    /* html {
        font-size: 5px;
    } */

    #ChooseCity {
        width: 60rem;
    }
}

@media screen and (max-width: 700px) and (orientation: landscape) {
    /* html {
        font-size: 5px;
    } */
}

@media screen and (max-width: 600px) and (orientation: landscape) {
    #Journal-game > img {
        width: 18rem;
    }

    #Journal-game > video {
        width: 18rem;
    }

    #Journal-sidebar > img {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        width: 15rem;
    }
}

@media screen and (max-width: 500px) and (orientation: landscape) {
    .App-title {
        display: none;
    }
}

/*PORTRAIT GENERAL*/

@media screen and (orientation: portrait) {
    *,
    *::after,
    *::before {
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .black-background-container {
        margin: 1.8cm 0cm 2cm 0cm;
    }

    .bFKHxN {
        text-indent: 0cm !important;
    }

    .kehHSs {
        text-indent: 0.6cm !important;
    }

    .iXfOHg {
        text-indent: 0.6cm !important;
    }

    .khuxJO {
        text-indent: 0.6cm !important;
    }

    .fCkYOx {
        text-indent: 0.6cm !important;
    }

    .cusJxr {
        text-indent: 0cm !important;
    }

    .dibUHP {
        text-indent: 0cm !important;
    }

    .jlwOzK {
        text-indent: 0cm !important;
    }

    .iBUoPN {
        text-indent: 0.6cm;
    }

    .hoieEY {
        font-size: 12pt !important;
    }

    .htTslG {
        font-size: 12pt !important;
    }

    .jkjGbK {
        font-size: 12pt !important;
    }

    .dUzIfN {
        text-indent: 0cm !important;
    }

    .App-title {
        font-size: 1.8rem;
    }

    .Nav-bar {
        display: none;
    }

    .video-container .rsme-embed {
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .youtube-iframe {
        position: relative;
        margin-top: unset;
    }

    #Choose-avatar {
        width: 100%;
    }

    #login-logo {
        width: 20rem;
    }

    .Content-central.portrait #login-logo {
        width: 27rem;
    }

    .portrait-login-register-title {
        font-size: 3.4rem !important;
    }

    .Content-central.portrait .error-note {
        font-size: 2.3rem;
    }

    .Content-central.portrait .Button {
        height: 6rem !important;
    }

    /*.oauth-icons {
        margin: 1rem;
        height: 7rem;
        width: 7rem;
    }

        .oauth-icons img {
            height: 100%;
        }*/

    .Content-central.portrait .text-input {
        width: 80%;
        height: 6rem;
        margin-left: auto;
        margin-right: auto;
    }

    .Content-central.portrait .text-input input {
        font-size: 2.5rem;
    }

    .register-input-list-portrait {
        width: 100% !important;
        flex-direction: column !important;
        margin-top: 1rem;
    }

    .register-inputs-collumn-portrait {
        width: 100%;
        display: block !important;
    }

    .register-inputs-collumn-landscape {
        display: none !important;
    }

    .start-client {
        height: 100% !important;
    }

    #choose-avatar-prompt {
        justify-content: center;
        margin-bottom: 2rem;
    }

    #Avatars-icon-list {
        flex-wrap: wrap;
        justify-content: center;
    }

    .Avatar-icon {
        width: calc((100% / 4) - (5rem));
    }

    #button-salvar-profile {
        width: 56%;
        border-radius: 2.5rem;
        margin-top: 1rem;
        margin-bottom: 5rem;
        text-align: center;
        padding: unset;
        font-size: 2.2rem;
    }

    #button-reset-password {
        width: 65%;
        border-radius: 2.5rem;
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        text-align: center;
        padding: unset;
        font-size: 2.2rem;
    }

    .black-background {
        justify-content: flex-start !important;
    }

    .banner-popup .popup-box {
        width: 50%;
        height: unset;
    }

    .popup-box .banner-popup-close {
        width: 3rem;
        height: 3rem;
    }

    #popup-play-button {
        height: 4rem;
        font-size: 1.6rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    #home-play-button {
        height: 4.5rem;
        font-size: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    #home-play-button-client {
        height: 4.5rem;
        font-size: 2rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .regulation-text {
        width: 60%;
        margin-right: 5%;
        align-self: end;
        font-size: 1.5rem;
    }

    .client-banner-class {
        width: 90% !important;
    }

    #home-half-banner-btn {
        top: unset;
        bottom: 6%;
        height: 5rem;
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 2.5rem;
    }

    .our-partners-text {
        width: 95%;
        font-size: 2.5rem;
    }

    .ChooseCity-container {
        width: 92%;
        flex-direction: column;
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    .Content-left {
        margin-top: unset;
    }

    #Selected-game-container {
        flex-direction: row;
        justify-content: space-between;
    }

    #Selected-game-container > img {
        width: 50%;
        height: unset;
    }

    #Selected-game-container > video {
        width: 50%;
        height: unset;
    }

    #ChooseCity--title-portrait {
        display: block;
    }

    #ChooseCity--title {
        display: none;
    }

    #ChooseCity {
        width: 100%;
        padding: unset;
    }

    #brasilmapimg {
        width: 100%;
    }

    .buttonButtons-list-map {
        display: none;
    }

    .buttonButtons-list-map-portrait {
        width: 100%;
        display: flex;
        margin-top: 2rem;
    }

    .buttonButtons-list-map-portrait .Button {
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 2.2rem;
        height: 4.5rem;
    }

    .Content-right {
        display: none;
    }

    .Content-central {
        width: 100% !important;
        align-items: center !important;
    }

    .Content-central.portrait {
        margin-top: 3rem !important;
    }

    #login-register-button {
        width: 65%;
        margin: 2rem;
        height: 5rem;
        font-size: 2.2rem;
    }

    #Journal-content {
        flex-direction: column;
    }

    .additional-text-journal {
        width: 100%;
    }

    #Journal-list {
        padding-left: unset;
        flex-direction: column;
    }

    .box-journal-top {
        width: 100%;
        display: flex;
    }

    .box-journal-top > img {
        width: 53%;
        object-position: center;
        margin-bottom: unset;
    }

    .box-journal-top > video {
        width: 53%;
        object-position: center;
        margin-bottom: unset;
    }

    .box-journal-items {
        width: 45%;
        margin-left: 2%;
    }

    .box-journal-items > img {
        width: 70% !important;
        margin-bottom: 1rem;
    }

    .box-journal-items .Button {
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.6rem;
        height: 5rem;
        width: unset !important;
    }

    .Journal-world {
        width: 100%;
    }

    .Journal-world.mundodaagua {
        margin-top: 12rem;
    }

    .Journal-world.mundodosaneamento {
        margin-top: 12rem;
    }

    .Journal-world.mundodosresiduos {
        margin-top: 17rem;
    }

    .box-mission-title-track {
        margin-top: 3rem;
    }

    .mission-title {
        position: absolute;
        left: 0;
        width: 38% !important;
        margin: 0.5rem 0 0 0;
    }

    .Journal-track {
        width: 100%;
        align-items: flex-end;
        margin-top: 2rem;
    }

    .challenge-list {
        width: 60% !important;
        right: 0;
        margin-bottom: 1rem;
    }

    .challenge {
        font-size: 1rem;
        margin-top: 1.2rem;
    }

    .challenge.mission {
        font-size: 1.8rem;
    }

    #play-world-button {
        font-size: 2.4rem;
        height: 5rem;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .error-note {
        width: 60%;
        margin-top: 0.5rem;
    }

    #VideoGallery-content {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .VideoGallery-column {
        display: none;
        width: 90%;
    }

    .VideoGallery-column.active {
        display: flex;
    }

    .public-video-gallery-list {
        flex-direction: column;
    }

    .public-video-gallery-list .video-container {
        width: 90% !important;
        margin-left: auto;
        margin-right: auto;
    }

    .choose-world-filter-galery {
        width: 32rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;
        display: block;
    }

    .choose-world-filter-galery .title {
        background-color: #2d4f6d;
        color: white;
        border-radius: 2rem;
        font-size: 2.5rem;
        line-height: 5rem;
        font-weight: 600;
    }

    .choose-world-filter-galery .options {
        background-color: #76bcf8;
        color: #2a2a2a;
        border-radius: 2rem;
        margin-top: 1.5rem;
        font-size: 2.2rem;
        line-height: 4rem;
        font-weight: 600;
        text-align: left;
        padding: 1.5rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 18rem;
        transition: 0.2s ease-in-out all;
    }

    .choose-world-filter-galery .options div {
        width: 100%;
    }

    #video-gallery-play-btn-container {
        padding-right: unset;
        justify-content: center;
    }

    .video-container-caption div {
        font-size: 2.2rem;
        line-height: 3rem;
    }

    .evaluations {
        font-size: 2.2rem;
        line-height: 3rem;
        justify-content: space-between;
    }

    .evaluations img {
        width: 6%;
    }

    .evaluations div {
        /*margin-right: 3rem;*/
        width: fit-content;
        align-items: flex-start;
    }

    .evaluations div:last-child {
        margin-right: unset;
    }

    #gallery-filters-container {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        font-size: 3rem;
    }

    #gallery-filter-value-container {
        width: 100%;
        margin-bottom: 1rem;
    }

    #gallery-filter-value-title {
        padding: unset;
        width: 100%;
        justify-content: center;
        font-size: 3rem;
        height: 7rem;
        border-radius: 2.5rem;
    }

    #gallery-filters-container .classCode {
        width: 100%;
    }

    #gallery-filter-value-content {
        width: 100%;
        top: 100%;
        font-size: 3rem;
    }

    #gallery-filter-value-content div {
        justify-content: center;
        text-align: center;
    }

    .questionIcon {
        width: 7rem;
        height: 7rem;
        left: 0;
    }

    .questionIcon .question-answer {
        width: 24rem;
        font-size: 2.4rem;
    }

    .classCode .text-input {
        width: calc(100% - 8rem) !important;
        margin-left: 8rem;
        height: 7rem;
        font-size: 3rem;
    }

    .filters-used-container .filter-used-text {
        font-size: 2.8rem;
    }

    .dashboard {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        width: 100%;
        margin-top: 8rem;
    }

    .dashboard-scroll {
        width: 100%;
        font-size: 2.5rem !important;
        line-height: 3.5rem !important;
    }

    .dashboard-block .Button {
        width: 95% !important;
        height: 6rem !important;
        border-radius: 3rem !important;
        margin: 3rem auto !important;
    }

    .dashboard-scroll .Button {
        width: 95% !important;
        height: 6rem !important;
        border-radius: 3rem !important;
        margin: 3rem auto !important;
    }

    .dashboard-block a {
        font-size: 3rem;
        line-height: 5.6rem;
        text-align: left;
    }

    .dashboard-subtitle {
        font-size: 3.4rem;
        line-height: 4rem;
        margin-bottom: 1rem;
        text-align: left;
    }

    .teacher-mobile-classCode {
        height: 3rem;
    }

    .teacher-mobile-deleteButton .Button {
        height: 4rem !important;
        width: 100% !important;
        margin-top: -4rem !important;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .dashboard-scroll .text-input {
        width: 95%;
        height: 7rem;
        margin: 0 auto;
    }

    .dashboard-scroll .text-input input {
        font-size: 2.5rem;
    }

    .dashboard-separator {
        display: none;
    }

    .dashboard-block.landscape {
        display: none;
    }

    .dashboard-block.portrait {
        display: block;
        margin-top: 4rem;
        text-align: left;
    }

    #notices {
        width: 80%;
        position: unset;
        font-size: 2rem;
        margin: 0rem auto 3rem auto;
        padding: 0rem;
        display: none;
    }

    #notices div {
        margin-bottom: 2rem;
    }

    .Content-central.extra-margin-top {
        margin-top: 11rem !important;
    }

    .Content-central.portrait {
        margin-left: 0 !important;
    }

    .teacher-login-register-portrait-text {
        width: 100% !important;
        height: auto !important;
    }

    .teacher-box-text-portrait {
        display: block;
    }

    .teacher-box-text-landscape {
        display: none;
    }

    .teacher-login-register-portrait-text p {
        /*padding: 2rem 1rem 0px 1rem !important;*/
        font-size: 2.3rem !important;
        line-height: 3.1rem;
    }

    .text-input.teacher-register-portrait {
        width: 100%;
    }

    .teacher-login-register-portrait-square {
        /*min-height: 95rem !important;*/
        min-height: 126rem !important;
        flex-direction: column;
    }

    .teacher-login-register-portrait-square.register {
        min-height: 150rem !important;
    }

    .teacher-register-create-box {
        width: 100% !important;
    }

    .teacher-register-create-box .Button {
        width: 80% !important;
        margin-top: 1rem !important;
    }

    .page-title {
        height: 10rem;
        top: -4rem !important;
    }

    .register-additional-terms-text {
        width: 90%;
    }

    .login-additional-terms-text {
        width: 90%;
    }
}
/*PORTRAIT GENERAL*/

@media screen and (max-width: 1040px) and (orientation: portrait) {
    html {
        /* font-size: 13px; */
    }

    .box-journal-items > img {
        width: 70% !important;
        margin-bottom: 3rem;
    }

    .box-journal-items .Button {
        padding-left: 4rem;
        padding-right: 4rem;
        font-size: 2rem;
        height: 5rem;
        width: unset !important;
    }

    .Journal-world-logo-container {
        height: 17rem;
        width: 58%;
    }

    .box-mission-title-track {
        width: 100%;
    }

    .challenge {
        font-size: 1.6rem;
        margin-top: 2.2rem;
        padding: 1rem;
    }
}

@media screen and (max-width: 950px) and (orientation: portrait) {
    .challenge {
        font-size: 1.5rem;
        margin-top: 2.2rem;
        padding: 0.5rem;
    }
}

@media screen and (max-width: 900px) and (orientation: portrait) {
    html {
        /* font-size: 10px; */
    }

    .challenge {
        padding: 1.1rem;
    }

    .mission-title {
        width: 38% !important;
    }

    .video-container .rsme-embed {
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 800px) and (orientation: portrait) {
    .challenge {
        padding: 0.7rem;
    }
}

@media screen and (max-width: 750px) and (orientation: portrait) {
    html {
        /* font-size: 10px; */
    }

    .challenge {
        padding: 0.7rem;
        width: 32%;
        font-size: 1.2rem;
        margin-top: 0.7rem;
    }
}

@media screen and (max-width: 700px) and (orientation: portrait) {
    html {
        /* font-size: 9px; */
    }

    .Journal-world.mundodaagua {
        margin-top: 5rem;
    }

    .Journal-world.mundodosaneamento {
        margin-top: 6rem;
    }

    .Journal-world.mundodosresiduos {
        margin-top: 11rem;
    }

    .video-container .rsme-embed {
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 550px) and (orientation: portrait) {
    html {
        /* font-size: 7px; */
    }
}

@media screen and (max-width: 550px) and (min-height: 660px) and (orientation: portrait) {
    html {
        /* font-size: 7px; */
    }

    .box-journal-items > img {
        margin-bottom: 1rem;
    }

    .box-journal-items .Button {
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 1.6rem;
    }

    .Journal-world.mundodaagua {
        margin-top: 11rem;
    }

    .Journal-world.mundodosaneamento {
        margin-top: 13rem;
    }

    .Journal-world.mundodosresiduos {
        margin-top: 18rem;
    }

    .challenge {
        font-size: 1.5rem;
        margin-top: 2.4rem;
    }
}

@media screen and (min-width: 500px) and (max-width: 550px) and (min-height: 660px) and (orientation: portrait) {
    .video-container .rsme-embed {
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 550px) and (min-height: 900px) and (max-height: 1000px) and (orientation: portrait) {
    .video-container .rsme-embed {
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 550px) and (min-height: 800px) and (max-height: 850px) and (orientation: portrait) {
    .challenge {
        font-size: 1.2rem;
        margin-top: 1.6rem;
        padding: 0.1rem;
    }

    .Journal-world.mundodaagua {
        margin-top: 7rem;
    }

    .Journal-world.mundodosaneamento {
        margin-top: 9rem;
    }

    .Journal-world.mundodosresiduos {
        margin-top: 12rem;
    }
}

@media screen and (max-width: 430px) and (max-height: 750px) and (orientation: portrait) {
    html {
        /* font-size: 7px; */
    }

    .box-journal-items > img {
        margin-bottom: 1rem;
    }

    .box-journal-items .Button {
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 1.6rem;
    }

    .Journal-world.mundodaagua {
        margin-top: 7rem;
    }

    .Journal-world.mundodosaneamento {
        margin-top: 8rem;
    }

    .Journal-world.mundodosresiduos {
        margin-top: 13rem;
    }

    .challenge {
        font-size: 1.2rem;
        margin-top: 0.7rem;
    }
}

@media screen and (max-width: 450px) and (max-height: 650px) and (orientation: portrait) {
    html {
        /* font-size: 6.5px; */
    }

    .box-journal-items > img {
        margin-bottom: 1rem;
    }

    .box-journal-items .Button {
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 430px) and (max-height: 700px) and (orientation: portrait) {
    .video-container .rsme-embed {
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 420px) and (orientation: portrait) {
    html {
        /* font-size: 6px; */
    }

    .challenge {
        font-size: 1rem;
        margin-top: 1.4rem;
        padding: 0.8rem;
    }
}

@media screen and (max-width: 330px) and (orientation: portrait) {
    html {
        /* font-size: 5px; */
    }

    #footer-copyright {
        font-size: 0.85rem;
        line-height: 1.7rem;
    }

    .video-container .rsme-embed {
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

.text-hover {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 14px;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s;
}

/* END RESPONSIVE */
